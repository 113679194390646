import React, { useContext } from 'react';
import '../Styles/FormPage.css'
import UserInfoPage from "./UserInfoPage";
import LegalPage from "./LegalPage";
import BrandSelectPage from './BrandSelectPage';
import CompleteRegisterPage from './CompleteRegisterPage';
import { multiStepContext } from '../StepContext'
import Sidebar from './Sidebar';
import PortalPage from './Portalpage';
import StatusPage from './StatusPage';
import TermPage from './TermPage';
import DetailPage from './DetailPage';
import MapPage from './MapPage';
import AccuracyPage from './AccuracyPage';
import CompleteStatusPage from './CompleteStatus';
import Authentication from './Authentication';
import NotFoundPage from './NotFoundPage';
import ApprovedPage from './ApprovedPage';
import ServicePage from './ServicePage';
import RegisterCheckPage from './RegisterCheckPage';
import NotApprovedPage from './NotApprovedPage';
import MobileFooter from '../tools/MobileFooter';
import MobileHeader from '../tools/MobileHeader';
import WaitingPage from './WaitingPage';
import AlreadySelectPage from './AlreadySelectPage';
import NotFoundStatus from './NotFoundStatus';
import AlreadyConfirm from './AlreadyConfirm';
import NotInTimePage from './NotInTimePage';
import NotPassStatus from './NotPassStatus';
import MaxRegisPage from './MaxRegisPage';
import NotLivePage from './NotLivePage';
import ClosePage from './ClosePage';
import MaintenancePage from './MaintenancePage';
import MaxRegisStatusPage from './MaxRegisStatusPage';

function FormPage() {

  const { currentStep, finalData } = useContext(multiStepContext);
  function showStep(step){
      switch(step){
        case 1:
          return(
            <div className="portal-style">
                <PortalPage/>
             </div>
          )
        case 2:
          return (
            <div className="form-page-con">
              <div className="form-side">
                <Sidebar/>
              </div>
              <div className="header">
                  <MobileHeader/>
                </div>
              <div className='form-style'>
                <Authentication/>
              </div>
              <div className='footer'>
                <MobileFooter/>
              </div>
            </div>
          )
        case 3:
          return (
            <div className="form-page-con">
              <div className="form-side">
                <Sidebar/>
              </div>
              <div className="header">
                  <MobileHeader/>
                </div>
              <div className='form-style'>
                <UserInfoPage/>
              </div>
              <div className='footer'>
                <MobileFooter/>
              </div>
            </div>
          )
        case 4:
          return (
            <div className="form-page-con">
              <div className="form-side">
                <Sidebar/>
              </div>
              <div className="header">
                  <MobileHeader/>
                </div>
              <div className='form-style'>
                <LegalPage/>
              </div>
              <div className='footer'>
                <MobileFooter/>
              </div>
            </div>
          )
        case 5:
          return (
            <div className="form-page-con">
              <div className="form-side">
                <Sidebar/>
              </div>
              <div className="header">
                  <MobileHeader/>
                </div>
              <div className='form-style'>
                <BrandSelectPage/>
              </div>
              <div className='footer'>
                <MobileFooter/>
              </div>
            </div>
          )
        case 6:
          return (
            <div className="form-page-con">
              <div className="form-side">
                <Sidebar/>
              </div>
              <div className="header">
                  <MobileHeader/>
                </div>
              <div className='form-style'>
                <CompleteRegisterPage/>
              </div>
              <div className='footer'>
                <MobileFooter/>
              </div>
            </div>
          )
        case 7:
          return (
            <div className="form-page-con">
              <div className="form-side">
                <Sidebar/>
              </div>
              <div className="header">
                  <MobileHeader/>
                </div>
              <div className='form-style'>
                <StatusPage/>
              </div>
              <div className='footer'>
                <MobileFooter/>
              </div>
            </div>
          )
        case 8:
          return (
            <div className="form-page-con">
              <div className="form-side">
                <Sidebar/>
              </div>
              <div className="header">
                  <MobileHeader/>
                </div>
              <div className='form-style'>
                <TermPage/>
              </div>
              <div className='footer'>
                <MobileFooter/>
              </div>
            </div>
          )
        case 9:
          return (
            <div className="form-page-con">
              <div className="form-side">
                <Sidebar/>
              </div>
              <div className="header">
                  <MobileHeader/>
                </div>
              <div className='form-style'>
                <DetailPage/>
              </div>
              <div className='footer'>
                <MobileFooter/>
              </div>
            </div>
          )
        case 10:
            return (
              <div className="form-page-con">
                <div className="form-side">
                  <Sidebar/>
                </div>
                <div className="header">
                  <MobileHeader/>
                </div>
                <div className='map-style'>
                  <MapPage/>
                </div>
                <div className='map-style-large'>
                  <MapPage/>
                </div>
                <div className='footer'>
                  <MobileFooter/>
                </div>
              </div>
          )
        case 11:
            return (
              <div className="form-page-con">
                <div className="form-side">
                  <Sidebar/>
                </div>
                <div className="header">
                  <MobileHeader/>
                </div>
                <div className='form-style'>
                  <AccuracyPage/>
                </div>
                <div className='footer'>
                  <MobileFooter/>
                </div>
              </div>
          )
        case 12:
            return (
              <div className="form-page-con">
                <div className="form-side">
                  <Sidebar/>
                </div>
                <div className="header">
                  <MobileHeader/>
                </div>
                <div className='form-style'>
                  <CompleteStatusPage/>
                </div>
                <div className='footer'>
                  <MobileFooter/>
                </div>
              </div>
          )
        case 13:
          return(
            <div className="form-page-con">
                <div className="form-side">
                  <Sidebar/>
                </div>
                <div className="header">
                  <MobileHeader/>
                </div>
                <div className='form-style'>
                  <NotFoundPage/>
                </div>
                <div className='footer'>
                  <MobileFooter/>
                </div>
              </div>
          )
        case 14:
          return(
            <div className="form-page-con">
                <div className="form-side">
                  <Sidebar/>
                </div>
                <div className="header">
                  <MobileHeader/>
                </div>
                <div className='form-style'>
                  <ApprovedPage/>
                </div>
                <div className='footer'>
                  <MobileFooter/>
                </div>
              </div>
            )
          case 15:
              return(
                <div className="form-page-con">
                <div className="form-side">
                  <Sidebar/>
                </div>
                <div className="header">
                  <MobileHeader/>
                </div>
                <div className='form-style'>
                  <ServicePage/>
                </div>
                <div className='footer'>
                  <MobileFooter/>
                </div>
              </div>
                )
          case 16:
              return(
                <div className="form-page-con">
                <div className="form-side">
                  <Sidebar/>
                </div>
                <div className="header">
                  <MobileHeader/>
                </div>
                <div className='form-style'>
                  <RegisterCheckPage/>
                </div>
                <div className='footer'>
                  <MobileFooter/>
                </div>
              </div>
            )
          case 17:
            return(
              <div className="form-page-con">
                <div className="form-side">
                  <Sidebar/>
                </div>
                <div className="header">
                  <MobileHeader/>
                </div>
                <div className='form-style'>
                  <NotApprovedPage/>
                </div>
                <div className='footer'>
                  <MobileFooter/>
                </div>
              </div>
            )  
          case 18:
            return(
              <div className="form-page-con">
                <div className="form-side">
                  <Sidebar/>
                </div>
                <div className="header">
                  <MobileHeader/>
                </div>
                <div className='form-style'>
                  <WaitingPage/>
                </div>
                <div className='footer'>
                  <MobileFooter/>
                </div>
              </div>
            )  
          case 19:
            return(
              <div className="form-page-con">
                <div className="form-side">
                  <Sidebar/>
                </div>
                <div className="header">
                  <MobileHeader/>
                </div>
                <div className='form-style'>
                  <AlreadySelectPage/>
                </div>
                <div className='footer'>
                  <MobileFooter/>
                </div>
              </div>
            ) 
          case 20:
            return(
              <div className="form-page-con">
                <div className="form-side">
                  <Sidebar/>
                </div>
                <div className="header">
                  <MobileHeader/>
                </div>
                <div className='form-style'>
                  <NotFoundStatus/>
                </div>
                <div className='footer'>
                  <MobileFooter/>
                </div>
              </div>
            )
          case 21:
              return(
                <div className="form-page-con">
                  <div className="form-side">
                    <Sidebar/>
                  </div>
                  <div className="header">
                    <MobileHeader/>
                  </div>
                  <div className='form-style'>
                    <AlreadyConfirm/>
                  </div>
                  <div className='footer'>
                    <MobileFooter/>
                  </div>
                </div>
              ) 
              case 22:
                return(
                  <div className="form-page-con">
                    <div className="form-side">
                      <Sidebar/>
                    </div>
                    <div className="header">
                      <MobileHeader/>
                    </div>
                    <div className='form-style'>
                      <NotInTimePage/>
                    </div>
                    <div className='footer'>
                      <MobileFooter/>
                    </div>
                  </div>
                )
              case 23:
                  return(
                  <div className="form-page-con">
                      <div className="form-side">
                        <Sidebar/>
                      </div>
                      <div className="header">
                        <MobileHeader/>
                      </div>
                      <div className='form-style'>
                        <NotPassStatus/>
                      </div>
                      <div className='footer'>
                        <MobileFooter/>
                      </div>
                    </div>
                ) 
              case 24:
                return(
                  <div className="form-page-con">
                    <div className="form-side">
                      <Sidebar/>
                    </div>
                    <div className="header">
                      <MobileHeader/>
                    </div>
                    <div className='form-style'>
                      <MaxRegisPage/>
                    </div>
                    <div className='footer'>
                      <MobileFooter/>
                    </div>
                  </div>
                )
                case 25:
                return(
                  <div className="form-page-con">
                    <div className="form-side">
                      <Sidebar/>
                    </div>
                    <div className="header">
                      <MobileHeader/>
                    </div>
                    <div className='form-style'>
                      <NotLivePage/>
                    </div>
                    <div className='footer'>
                      <MobileFooter/>
                    </div>
                  </div>
                )
                case 26:
                return(
                  <div className="form-page-con">
                    <div className="form-side">
                      <Sidebar/>
                    </div>
                    <div className="header">
                      <MobileHeader/>
                    </div>
                    <div className='form-style'>
                      <ClosePage/>
                    </div>
                    <div className='footer'>
                      <MobileFooter/>
                    </div>
                  </div>
                )
                case 27:
                return(
                  <div className="form-page-con">
                    <div className="form-side">
                      <Sidebar/>
                    </div>
                    <div className="header">
                      <MobileHeader/>
                    </div>
                    <div className='form-style'>
                      <MaintenancePage/>
                    </div>
                    <div className='footer'>
                      <MobileFooter/>
                    </div>
                  </div>
                )
                case 28:
                return(
                  <div className="form-page-con">
                    <div className="form-side">
                      <Sidebar/>
                    </div>
                    <div className="header">
                      <MobileHeader/>
                    </div>
                    <div className='form-style'>
                      <MaxRegisStatusPage/>
                    </div>
                    <div className='footer'>
                      <MobileFooter/>
                    </div>
                  </div>
                )
      }

  }

  return (
    <div className='render-area'>
      <div className="form-container">
        <div className="form-question">
          { showStep(currentStep)}
        </div>
      </div>
    </div>
  );
}

export default FormPage;
