import React, { useContext , useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import serviceProcessBar from '../assets/service-process-bar.png';
import '../Styles/ServicePage.css'

const ServicePage = () => {
  const {setStep, userData, setUserData} = useContext(multiStepContext);

  useEffect(() => {
    if (!userData.phoneNumberOption) {
      setUserData({ ...userData, phoneNumberOption: "ไม่ใช่" });
    }
  }, [setUserData, userData]);

  
  return (
    <div className="service-container">
        <div className="service-process-bar">
            <img src={serviceProcessBar} className="service-pro-bar" alt="Service Process Bar" />
        </div>
        <div className="service-info">
            โปรดกรอกข้อมูลลงในแบบฟอร์ม
            <br />
            <span style={{ fontSize: '16' }}>Please fill out  the form with your information</span>
        </div>
        <div className="service-choice">
            ท่านประสงค์ที่จะ
            <select className="service-option" value={userData['phoneNumberOption']} onChange={(e) => setUserData({...userData, "phoneNumberOption": e.target.value})}>
              <option value="ไม่ใช่">ใช้หมายเลขโทรศัพท์เดิม</option>
              <option value="ใช่">รับซิมการ์ดใหม่</option>
            </select>
        </div>
        <div className="form-bt">
            <button onClick={() => setStep(4)} className="form-back-bt">ย้อนกลับ</button>
            <button onClick={() => setStep(5)} className="form-next-bt">ต่อไป</button>
        </div>
    </div>
  )
}

export default ServicePage