import React, { useContext, useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, Tooltip } from 'react-leaflet';
import { Icon, DivIcon } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import '../Styles/Map.css';
import { multiStepContext } from '../StepContext';
import Axios from 'axios';

const Map = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const { setStep, userData, finalData, setUserData } = useContext(multiStepContext);
  const statusId = userData.statusId;
  const loId = userData.serviceID;
  const loName = userData.locationName;
  const [markers, setMarkers] = useState([]);
  const [coor, setCoor] = useState({
    lat: 13,
    lng: 104
  });
  const mapRef = useRef(null)

  useEffect(() => {
    if (userData.locationLat !== undefined && userData.locationLong !== undefined) {
      setCoor({
        lat: userData.locationLat,
        lng: userData.locationLong
      });
    }
  }, [userData.locationLat, userData.locationLong]);

  useEffect(() => {
    if (statusId && loId && loName) {
      getServiceAndNum(statusId, loId, loName);
    }
  }, [statusId, loId, loName]);

  const [position, setPosition] = useState(null)
  
  const HandleClickMap = () => {
    const map = useMapEvents({
      click(e){
          setPosition(e.latlng)
          map.flyTo({lat:coor.lat, lng:coor.lng})
        }
    })
    return null;
  };

  useEffect(() => {
    const flyToInitialPosition = () => {
      if (mapRef.current && coor.lat && coor.lng) {
        mapRef.current.flyTo([coor.lat, coor.lng]);
      }
    };

    flyToInitialPosition();
  }, [coor.lat, coor.lng]);

  const getServiceAndNum = async (statusId, loId, loName) => {
    try {
      const response = await Axios.get(`${apiURL}getBranch/${userData.serviceID}/${loName}`);
      const branches = response.data.OpBranch;

      const newMarkers = branches.map(branch => ({
        geocode: [parseFloat(branch.lat), parseFloat(branch.Long)],
        popUp: branch.name
      }));

      setMarkers(newMarkers);
    } catch (error) {
      console.error('Error fetching service and number:', error);
    }
  }

  const customIcon = new Icon({
    iconUrl: require("../assets/placeholder.png"),
    iconSize: [38, 38]
  });

  const createCustomClusterIcon = (cluster) => {
    return new DivIcon({
      html: `<div class="cluster-icon">${cluster.getChildCount()}</div>`,
      className: "custom-marker-cluster"
    });
  }

  return (
    <div>
      <MapContainer
        style={{ width: '100%', height: '350px' }}
        center={[13.743393, 100.509374]}
        zoom={10}
        scrollWheelZoom={false}
        ref={mapRef}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <HandleClickMap/>
        <MarkerClusterGroup
          chunkedLoading
          iconCreateFunction={createCustomClusterIcon}
        >
          {markers.map((marker, index) => (
            <Marker key={index} position={marker.geocode} icon={customIcon}>
              <Popup>{marker.popUp}</Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
}

export default Map;
