import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/UserInfoPage.css'
import formProcessBar from '../assets/form-status-bar.png';
import Axios from 'axios';

const UserInfoPage = () => {
  const {setStep, userData, setUserData} = useContext(multiStepContext);
 
  const handleFirstNameChange = (e) => {
    const trimmedValue = e.target.value.trim();
    setUserData({ ...userData, firstname: trimmedValue });
  }

  const handleLastNameChange = (e) => {
    const trimmedValue = e.target.value.trim();
    setUserData({ ...userData, lastname: trimmedValue });
  }

  return (
    <div className="userInfo-container">
        <div className="userInfo-process-bar">
            <img src={formProcessBar} className="userInfo-pro-bar" alt="Form Process Bar" />
        </div>
        <div className="userInfo-info">
            โปรดกรอกข้อมูลในแบบฟอร์ม
            <br />
            <span style={{ fontSize: '16' }}>Please indicate the most suitable option</span>
        </div>
        <div className="userInfo-input">
            <div className="userInfo-name">
              <div className="userInfo-fname">
                   ชื่อ
                  <input type="text" 
                    className='form-text' 
                    placeholder='โปรดกรอกชื่อจริง' 
                    value={userData['firstname']} 
                    onChange={handleFirstNameChange}></input>
              </div>
              <div className="userInfo-lname">
                  นามสกุล (ถ้าไม่มีกรุณาใส่ -)
                <input type="text" 
                  className='form-text' 
                  placeholder='โปรดกรอกนามสกุล' 
                  value={userData['lastname']} 
                  onChange={handleLastNameChange}></input>
              </div>
            </div>
            <div className="userInfo-id">
              <div className="userInfo-id">
                หมายเลขบัตรประชาชน
                <input 
                    type="text" 
                    className='form-id' 
                    placeholder='โปรดกรอกหมายเลขบัตรประชาชน' 
                    value={userData['citizenId']} 
                    readOnly
                  />
              </div>
            </div>
        </div>
        <div className="form-bt">
            <button onClick={() => setStep(4)} className="form-next-bt">ต่อไป</button>
        </div>
    </div>
  );
};

export default UserInfoPage;