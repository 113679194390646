import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/Authentication.css'
import Axios from 'axios';

const Authentication = () => {
  const [inputValue, setInputValue] = useState('');
  const { setStep, userData, setUserData } = useContext(multiStepContext);

  const apiURL = process.env.REACT_APP_API_URL;

   const [data, setData] = useState("");
  
  const checkNumber = async () => {

    if(inputValue.length !== 13){
      alert('กรุณากรอกหมายเลขบัตรประชาชนให้ครบ 13 หลัก')
    }else{

      const response = await Axios.get(apiURL+`checkStudentNationalID/${inputValue}`);
      if (response.data.result === "Found") {
        setUserData({ ...userData, citizenId: inputValue }); 
        setStep(3);
      } 
      else if (response.data.result === "nameExisted"){
        setStep(21);
      }
      else if (response.data.result === "FoundNotInTime"){
        setStep(22);
      }
      else if (response.data.result === "FoundMaxRegis"){
        setStep(24);
      }
      else {
        setStep(17);
      }
    }
  };

  const [agreeTerms, setAgreeTerms] = useState(false);

    const handleCheckboxChange = () => {
        setAgreeTerms(!agreeTerms);
    };

    const handleInputChange = (e) => {
      let numericValue = e.target.value.replace(/\D/g, '').trim();
      if (numericValue.length > 13) {
          numericValue = numericValue.slice(0, 13);
      }
      setInputValue(numericValue);
  }
  

  return (
    <div className="authen-container">
        <div className="authen-info">
              ตรวจสอบสิทธิของท่าน
              <br/>
              <span style={{ fontSize: '16' }}>Check your eligibility</span> 
        </div>
        <div className='authen-input'>
          <div className="authen-id">
            <div className="authen-id-info">
            หมายเลขบัตรประจําตัวประชาชน
            </div>
            <input
                className="id-text"
                type='text'
                placeholder='โปรดกรอกข้อมูล'
                value={inputValue}
                onChange={handleInputChange}
              />
          </div>
        </div>
        <div className='authen-next-bt'>
            <label className="policy-con">
                <input type="checkbox" checked={agreeTerms} onChange={handleCheckboxChange}/> 
                <div className='policy-info'>
                    ข้าพเจ้ายินยอมให้ใช้ข้อมูลส่วนตัวสําหรับการตรวจสอบการได้รับสิทธิเข้าร่วมโครงการ ชื่อโครงการ<br/>
                    <span className="policy">
                        I hereby consent to my personal data being processed for the purpose of considering my eligibility
                    </span>
                </div>
            </label>
            <div className="bt-position">
              <button className="authen-back-bt" onClick={() => setStep(1)}>ย้อนกลับ</button>
              <button onClick={checkNumber} disabled={!agreeTerms} className="authen-next-button">
                  ต่อไป
              </button>
            </div>
        </div>
    </div>
  );
};

export default Authentication;