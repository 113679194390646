import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/AccuracyPage.css'
import Axios from 'axios';

const AccuracyPage = () => {
    const apiURL = process.env.REACT_APP_API_URL;

    const { setStep, userData, setUserData, submitData } = useContext(multiStepContext);
    const location = userData.locationOption;
    const finalData = useContext(multiStepContext).finalData;
    const branchId = parseInt(userData.branchId);

    const statusId = userData.statusId;

    const [type, setType] = useState("");

    const updateStatus = async (statusId) => {
        const response = await Axios.put(`${apiURL}changeStudentStatus/${statusId}`,{
            branchId: branchId
        }); 
    }
    
    const getServiceAndNum = async (statusId) => {
        const response = await Axios.get(`${apiURL}getStudentOperationRecord/${statusId}`);
        setType(response.data);
    }

    const serviceDetail = type && type.OpInfo.studentInfo[0].operators.company_name;
    const numberDetail = type && type.OpInfo.studentInfo[0].phoneNumber;
    const purposeNumber = type && type.purpose
    const packageType = type && type.OpInfo.studentInfo[0].packages.packageName

    const accBackStep = () => {
        if (purposeNumber === "ไม่ใช่") {
            setStep(9);
        } else {
            setStep(10);
        }
      };

    const renderPurpose = () => {
            if(numberDetail){ return numberDetail;}
            else{ return 'รับซิมการ์ดใหม่ที่สาขา'}
    };

    const handleLocation = () => {
        if (purposeNumber === "ใช่") {
          return (
            <div className='acc-location'>
                รับซิมการ์ดที่ศูนย์บริการ
                <input type="text" className='status-id' value={userData.branchName} readOnly></input>
            </div>
          );
        } else {
          return null;
        }
      };

    useEffect(() => {
        if (statusId) {
            getServiceAndNum(statusId);   
            updateStatus(statusId);
        }
      }, [statusId]);

    return(
        <>
        {
                <div className="acc-body">
                    <div className="acc-container">
                        <div className="acc-area-con">
                            <div className="acc-info">
                                    โปรดตรวจสอบความถูกต้อง
                                    <br/>
                                    <span style={{ fontSize: '16' }}>Please check the accuracy</span> 
                            </div>
                            <div className="acc-service-detail">
                                <div className="acc-service">
                                    เครือข่าย
                                    <input type="text" className='acc-text' value={serviceDetail} readOnly></input>
                                </div>
                                <div className="acc-phone">
                                    หมายเลขโทรศัพท์
                                    <input type="text" className='acc-text' value={renderPurpose()} readOnly></input>
                                </div>
                            </div>
                            <div className='acc-package'>
                                แพ็คแกจ
                                <input type="text" className='status-id' value={packageType} readOnly></input>
                            </div>
                            {handleLocation()}
                            <div className="acc-bt">
                                <button onClick={accBackStep} className="acc-back-bt">ย้อนกลับ</button>
                                <button onClick={() => { updateStatus(statusId) ;submitData(); setStep(12); }} className="acc-next-bt">ยืนยันข้อมูล</button>
                            </div>
                        </div> 
                    </div>
                </div> 
        }
        </> 
    );
}

export default AccuracyPage;