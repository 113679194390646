import React from 'react'
import '../Styles/SidebarFooter.css'
import aisSideLogo from '../assets/ais-logo-side.png';
import dtacSideLogo from '../assets/dtac-logo-side.png';
import trueSideLogo from '../assets/true-logo-side.png';

const SidebarFooter = () => {
  return (
    <div className="side-logo">
        <img src={aisSideLogo}></img>
        <img src={trueSideLogo}></img>
        <img src={dtacSideLogo}></img>
    </div>
  )
}

export default SidebarFooter